import { Injectable, HostListener } from '@angular/core';
import { SimpleCrypt } from 'ngx-simple-crypt';
import { BehaviorSubject } from 'rxjs';
import { ToastrManager } from 'ng6-toastr-notifications';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  public cart;
  public items = [];
  public isCheckout = false;
  private simpleCrypt = new SimpleCrypt();
  public carrito$ = new  BehaviorSubject<any>(this.items);
  public total = 0;
  public envio = 130;
  public subTotal = 0;
  public descontado = 0;
  public success = false;
  public innerWidth = window.innerWidth;
  private key = 'it%G|d+}_RR!hQgN#?^x<(@g]6&?Jz';
  @HostListener('window:resize', ['$event'])
  onResize(event) {
  this.innerWidth = window.innerWidth;
}
  constructor(private toastr: ToastrManager) { }

  addItem(item){
    if(this.items.length){
      if(this.items.every( i => item.id != i.id ) || item.personalizable) {
        this.items.push(item);
      } else {
        this.items.forEach( itemCart => {
          if (item.id === itemCart.id) {
            itemCart.cantidad += item.cantidad;
          }
      });
    }
      this.calcularTotal(false,null);
      this.carrito$.next(this.items);
    } else {
      this.items.push(item);
      this.calcularTotal(false, null);
      this.carrito$.next(this.items);
    }
    this.toastr.successToastr('Producto añadido correctamente', '',{toastTimeout: 2400, dismiss: 'auto',maxShown: 1, position: 'bottom-right'});

    this.setLSItems();
  }
  removeItem(index){
    this.calcularTotal(true, index);
    this.items.splice(index, 1);
    this.toastr.successToastr('Producto removido correctamente', '',{toastTimeout: 2400, dismiss: 'auto',maxShown: 1, position: 'bottom-right'});

    this.carrito$.next(this.items);
    this.setLSItems();
  }
  substractQty(item){
    this.items.forEach(i => {
      if(i.id === item.id){
        i.cantidad--;
        this.calcularTotal(false,null);
        this.setLSItems();
        this.carrito$.next(this.items);
        this.toastr.successToastr('Producto removido correctamente', '',{toastTimeout: 2400, dismiss: 'auto',maxShown: 1, position: 'bottom-right'});
      }
    });

  }
  calcularEnvio(express, esOrigen) {
    this.envio = this.subTotal >= 990 ? 0 : express ? 180 : (esOrigen ? 50 : 130);
  }
  addQty(item){
    this.items.forEach(i => {
      if(i.id === item.id){
        i.cantidad++;
        this.calcularTotal(false,null);
        this.setLSItems();
        this.carrito$.next(this.items);
        this.toastr.successToastr('Producto añadido correctamente', '',{toastTimeout: 1000, dismiss: 'auto',maxShown: 1, position: 'bottom-right'});
      }
    });

  }
  loadCart(){
    if(localStorage.getItem('amdCart')) {
      this.items = this.decryptCart();
      this.calcularTotal(false, null);
      this.carrito$.next(this.items);
    } else {
      this.cart = null;
      this.items = [];
    }
  }
  scrollTo(top: number){
    window.scrollTo({
      top: top,
      left: 0,
      behavior: 'smooth'
    });
  }
  decryptCart() {
    const cart = JSON.parse(localStorage.getItem('amdCart'));
    const decodedString = this.simpleCrypt.decode(this.key,cart);
    return JSON.parse(decodedString);
  }
  clearCart(){
    localStorage.clear();
    this.items = [];
    this.total = 0;
    this.subTotal = 0;
    this.carrito$.next(this.items);

  }
  calcularTotal(eliminar, i){
    if(this.items.length === 0){
      return this.total = 0;
    }
    if(eliminar){
      this.subTotal -= this.items[i].precio * this.items[i].cantidad;
      this.total -= this.items[i].precio * this.items[i].cantidad;
    }else{
      var cantidad = 0;
      this.items.forEach((item) => {
        cantidad += item.precio * item.cantidad;
      });
      this.total = cantidad;
      this.subTotal = cantidad;
    }
  }


  private setLSItems(){
    const encodedString = this.simpleCrypt.encode(this.key,JSON.stringify(this.items));
    localStorage.setItem('amdCart',JSON.stringify(encodedString));
  }
}
