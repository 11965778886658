import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { Globals } from '../globals';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class ProductosService {

  constructor(private http: HttpClient, private globals: Globals, private router: Router) { }

  getProductosTipo(parametros) {
    return this.http.get(`${this.globals.apiUrl}/productos/getProductosTipo?parametros=${parametros}`).pipe(catchError(this.handleError));
  }
  getCategorias() {
    return this.http.get(`${this.globals.apiUrl}/productos/getCategorias`).pipe(catchError(this.handleError));
  }
  getRandomTipo(parametros) {
    return this.http.get(`${this.globals.apiUrl}/productos/getProductosRandomTipo?parametros=${JSON.stringify(parametros)}`).pipe(catchError(this.handleError));
  }
  getRandom(parametros) {
    return this.http.get(`${this.globals.apiUrl}/productos/getProductosRandom?parametros=${JSON.stringify(parametros)}`).pipe(catchError(this.handleError));
  }
  getProducto(idProducto) {
    return this.http.get(`${this.globals.apiUrl}/productos/getProducto?id=${idProducto}`).pipe(catchError(this.handleError));
  }
  irDetalle(producto) {
    const parametro = `?nombre=${encodeURIComponent(producto.nombre)}&i=${producto.id}`;
    this.router.navigate(['/productos/detalle', parametro]);
  }
/*
  getRecomendados() {
    return this.http.get(`${this.globals.apiUrl}/productos/getRecomendados`).pipe(catchError(this.handleError));
  }
  getProductosRandom() {
    return this.http.get(`${this.globals.apiUrl}/productos/getProductosRandom`).pipe(catchError(this.handleError));
  } */
  getParametros(url) {
    // get query string from url (optional) or window
    var queryString = url ? url.split('?')[1] : window.location.search.slice(1);
    // we'll store the parameters here
    var obj = {};
    // if query string exists
    if (queryString) {
      // stuff after # is not part of query string, so get rid of it
      queryString = queryString.split('#')[0];
      // split our query string into its component parts
      var arr = queryString.split('&');
      for (var i = 0; i < arr.length; i++) {
        // separate the keys and the values
        var a = arr[i].split('=');
        // set parameter name and value (use 'true' if empty)
        var paramName = a[0];
        var paramValue = typeof (a[1]) === 'undefined' ? true : a[1];
        // (optional) keep case consistent
        paramName = paramName.toLowerCase();
        if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase();
        // if the paramName ends with square brackets, e.g. colors[] or colors[2]
        if (paramName.match(/\[(\d+)?\]$/)) {
          // create key if it doesn't exist
          var key = paramName.replace(/\[(\d+)?\]/, '');
          if (!obj[key]) obj[key] = [];
          // if it's an indexed array e.g. colors[2]
          if (paramName.match(/\[\d+\]$/)) {
            // get the index value and add the entry at the appropriate position
            var index = /\[(\d+)\]/.exec(paramName)[1];
            obj[key][index] = paramValue;
          } else {
            // otherwise add the value to the end of the array
            obj[key].push(paramValue);
          }
        } else {
          // we're dealing with a string
          if (!obj[paramName]) {
            // if it doesn't exist, create property
            obj[paramName] = paramValue;
          } else if (obj[paramName] && typeof obj[paramName] === 'string'){
            // if property does exist and it's a string, convert it to an array
            obj[paramName] = [obj[paramName]];
            obj[paramName].push(paramValue);
          } else {
            // otherwise add the property
            obj[paramName].push(paramValue);
          }
        }
      }
    }
    return obj;
  }
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
        console.log(`Backend returned error ${error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }

}
