import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { NotFoundComponent } from './components/not-found/not-found.component';

const routes: Routes = [
  {
      path: '',
      component: LayoutComponent,
      children: [
        { path: '', redirectTo: 'inicio', pathMatch: 'full'},
        {
          path: 'inicio',
          loadChildren: './components/inicio/inicio.module#InicioModule',
        },
        {
          path: 'productos',
          loadChildren: './components/productos/productos.module#ProductosModule',
        },
        {
          path: 'contacto',
          loadChildren: './components/contacto/contacto.module#ContactoModule',
        },
        {
          path: 'info',
          loadChildren: './components/estatico/estatico.module#EstaticoModule',
        },
        {
          path: 'checkout',
          loadChildren: './components/checkout/checkout.module#CheckoutModule',
        },
        {
          path: 'carrito',
          loadChildren: './components/shopping/shopping.module#ShoppingModule',
        },
        {path: '404', component: NotFoundComponent},
      ]

  },
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
