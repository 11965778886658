import { Component, OnInit } from '@angular/core';
declare var window: any;
import { CartService } from './services/cart.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'Turkana';

  constructor(private cartService: CartService) {
    this.cartService.loadCart();
  }

  ngOnInit() {
    window.Mercadopago.setPublishableKey('APP_USR-cb59d530-e525-4927-92d9-cf48d6eba792');
  }
}
