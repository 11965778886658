import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Globals } from '../globals';

@Pipe({
  name: 'img'
})
export class ImgPipe implements PipeTransform {
  constructor(private globals:Globals ,private sanitizer: DomSanitizer){}
  transform(uuid: any, id: any): any {
    if(uuid){
      let url = `${this.globals.apiUrl}/UploadedFiles/getUploadedFiles?&uuid=${uuid}&id=${id}&random=${Math.floor(Math.random()*1000)}`;
      let secureUrl = this.sanitizer.bypassSecurityTrustUrl(url);
      return secureUrl;
    } else {
      return 'assets/img/noimage.png';
    }
  }

}
