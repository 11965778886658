import { Component, OnInit, ChangeDetectorRef, OnDestroy, DoCheck } from '@angular/core';
import { Router, NavigationEnd, RouterEvent } from '@angular/router';
import { takeUntil, filter } from 'rxjs/operators';
import { CartService } from '../../services/cart.service';
import { Subject } from 'rxjs';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ProductosService } from '../../services/productos.service';
declare var $:any;
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit, DoCheck,OnDestroy {
  public carritoItems = [];
  public itemsQty = 0;
  public total = 0;
  public envio = 100;
  public isCheckout = false;
  private unsubscribe$: Subject<any> = new Subject<any>();
  public abrirDrop = false;
  public dropOpen;
  public categorias = []
  constructor(private cartService: CartService, private toastr:ToastrManager,private cd: ChangeDetectorRef, private router: Router, private ProductService: ProductosService) {
    this.isCheckout = this.cartService.isCheckout;
    this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationEnd)
    ).subscribe((event) => {
      if(event.url === "/checkout") {
        this.isCheckout = true;
        this.cartService.isCheckout = this.isCheckout;
      } else {
        this.isCheckout = false;
        this.cartService.isCheckout = false;
        if($('#collapseCart').hasClass('show')){
          $('#collapseCart').addClass('collapse');
          $('#collapseCart').removeClass('show');
        }
        /* if($('#collapseCartTwo').hasClass('show')){
          $('#collapseCartTwo').addClass('collapse');
          $('#collapseCartTwo').removeClass('show');
        } */
      }
      $('#drop-menu').hide();
      this.cartService.scrollTo(0);
    });
    this.carritoItems = this.cartService.items || [];
    this.total = this.cartService.total;
    this.itemsQty = this.carritoItems.length;
    this.cartService.carrito$.pipe(
    takeUntil(this.unsubscribe$))
      .subscribe( (items: any) => {
        if(items.length > 0){

          this.carritoItems = items;
          this.total = this.cartService.total;
          this.itemsQty = this.carritoItems.length;
          if(this.cartService.innerWidth > 421){
            let dropOpen: HTMLElement = document.getElementById('drop') as HTMLElement;
            if(dropOpen != undefined){
              if ($('.dropdown').find('.dropdown-menu').is(':hidden')){
                $('#drop-menu').show();
              }
            }
          }
      } else {
        this.carritoItems = [];
        this.total = 0;
        this.itemsQty = 0;
      }
    });
  }
  ngOnInit() {
   this.ProductService.getCategorias().subscribe((categorias:any) => this.categorias = categorias);
   
    if(this.carritoItems.length > 0){
      if(this.cartService.innerWidth > 421){
        if ($('.dropdown').find('.dropdown-menu').is(':hidden')){
          $('#drop-menu').show();
        }
      }
    }
    var  tb = $('.scrollcolor');
  var tbs = "change-nav";
  $(window).scroll(function() {
  if($(this).scrollTop()) {
      tb.addClass(tbs);
      $('.menu.fixed-top').css('height','80px');
      $('.menu.fixed-top').css('box-shadow','0px 0px 6px 0px #c2c2c2');
      $('.menu.fixed-top').css('background-color','rgba(255,255,255,1)');
      $('.menu.fixed-top').css('box-shadow','0px 7px 11px 0px #c2c2c2');
      $('.img-top').css('width','15rem');
      $('.navbar-brand').css('height','3rem');
      /* $('.navbar.navbar-expand-lg').css('height','5.5rem'); */
      $('.itemTop').css('height','0px');
      $('.collapse.navbar-collapse, .navbar-brand').css('padding-top','0rem');
      $('.scrollcolor').css('margin-top','1rem');
      $('.slide').css('margin-top','6rem');
      $('.menuTwo').css('top','5.8rem');
  } else {
      tb.removeClass(tbs);
      $('.menu.fixed-top').css('height','');
      $('.menu.fixed-top').css('box-shadow','');
      $('.menu.fixed-top').css('background-color','');
      $('.menu.fixed-top').css('box-shadow','');
      $('.img-top').css('width','');
      $('.navbar-brand').css('height','');
      /* $('.navbar.navbar-expand-lg').css('height',''); */
      $('.itemTop').css('height','');
      $('.scrollcolor').css('margin-top','');
      $('.collapse.navbar-collapse, .navbar-brand').css('padding-top','');
      $('.slide').css('margin-top','');
      $('.menuTwo').css('top','');
  }
  });
    $('.backBtn').click(function() {
      $('.menuTwo').removeClass('right-open left-open pushleft-open pushright-open');
      $('.menuOne').removeClass('right-open left-open pushleft-open pushright-open');
      $('body').removeClass('push-toleft push-toright');
    });
    $('#showRight').click(function() {
        $('.menu-right').toggleClass('right-open');
    });
    $('#showRightOne').click(function() {
        $('.menu-rightTwo').toggleClass('right-open');
    });
    $('#showRightPush').click(function () {
        $('body').toggleClass('push-toright');
        $('.push-right').toggleClass('pushright-open');
    });
    $('#showLeftPush').click(function () {
        $('body').toggleClass('push-toleft');
        $('.push-left').toggleClass('pushleft-open');
    });

  }
  ngDoCheck() {
    this.cd.markForCheck();
  }
  ngOnDestroy(){
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    }
    openList(e){
      if(this.cartService.innerWidth > 421){
      this.mostrarDrop();
    } else {
      this.router.navigate(['/shopping']);
    }
  }
  mostrarDrop(){
      if ($('.dropdown').find('.dropdown-menu').is(':hidden')){
        $('#drop-menu').show();
      } else {
        $('#drop-menu').hide();
      }
  }
trackByFn(index, item) {
  return index; // or item.id
}

removeItem(i){
  this.cartService.removeItem(i);
}
  myFunction(x) {
    x = document.getElementById("navbartoggler");
    x.classList.toggle("change");
  }

  functiontwo(x) {
    x = document.getElementById("navbartoggler");
    x.classList.toggle("menuTop");
  }
  

}
