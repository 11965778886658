import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { ItemCardComponent } from './item-card/item-card.component';
import { RouterModule } from '@angular/router';import { ToastrModule } from 'ng6-toastr-notifications';
import { PipesModule } from '../pipes/pipes.module';
@NgModule({
  declarations: [
    NavbarComponent,
    FooterComponent,
    ItemCardComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    PipesModule
  ],
  exports: [
    NavbarComponent,
    FooterComponent,
    ItemCardComponent,    
    ToastrModule
  ]
})
export class PartialsModule { }
