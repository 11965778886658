import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProductosService } from '../../services/productos.service';

@Component({
  selector: 'app-item-card',
  templateUrl: './item-card.component.html',
  styleUrls: ['./item-card.component.css']
})
export class ItemCardComponent implements OnInit {
  @Input() item:any;
  constructor(private router: Router, private productosService: ProductosService) { }

  ngOnInit() {
  }
  irDetalle(producto) {
    this.productosService.irDetalle(producto);
  }

}
